<template>
  <div class="box">
    <touTop/>
    <div class="box_main">
      <div v-if="menu_index== 1">
        <Zhuye></Zhuye>
      </div>
    </div>
  </div>
</template>
<script>
import Zhuye from "@/components/zhuye/zhuye";
import touTop from '@/components/touTop/touTop'

export default {
  data() {
    return {
        show:'',
        titleName:'',
      menus: [
        { name: "主界面" }
      ],
      nowIndex: 0,
      menu_index: 1
    };
  },
  components: {
    Zhuye,
    touTop
  },
  created() {
      
  },
  mounted(){
    // this.titleName
  },
  computed: {},
  methods: {
      againdata(){
          this.$confirm({
        title: "提示",
        content: "确定要重新加载页面么?",
        okText: "确定",
        cancelText: "取消",
        onOk() {
          window.location.reload();
        },
        onCancel() {}
      });
      },
      datayuan(){
          this.$info({
        title: "提示",
        content: "功能暂未开放,敬请期待",
        okText: "确定",
        onOk() {}
      });
      },
      dataxie(){
          this.$info({
        title: "提示",
        content: "功能暂未开放,敬请期待",
        okText: "确定",
        onOk() {}
      });
      },
      back(){
        if(this.$route.query.title=='营业'){
          this.$router.push('/ying')
        }
      }
  }
};
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.color {
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-right: 2px solid white;
}
.box {
  width: 100vw;
  height: 100vh;
  // position: fixed;
  background: #2e3e4e;
 
  .box_main {
    height: 95.4vh;
    >div{
      width: 100%;
      height: 100%;
    }
  }
  .bottom {
    display: flex;
    width: 100%;
    height: 30px;
    background: #2e3e4e;
    border-top: 1px solid #3a4958;
    position: fixed;
    margin-top: 6%;
    p {
      margin-top: 20px;
      margin-right: 10px;
      color: #7998ab;
      span:nth-of-type(2) {
        color: white;
        cursor: pointer;
      }
    }
    .p {
      margin-left: 35%;
    }
  }
}
</style>
