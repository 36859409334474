<template>
  <div class="box_ul">
    <p class="box_con">
      <!-- <span class="iconfont icon-tuichu"></span>
      <span>退出账号</span> -->
    </p>
    <div class="menuBox">
      <ul class="firsthang">
        <li @click="yBtn">
          <p>
            <img :src="require('../../assets/yingye.svg')" alt="" />
          </p>
          <p>{{ ying }}</p>
        </li>
        <li @click="mis">
          <p>
            <img :src="require('../../assets/huiyuan.png')" alt="" />
          </p>
          <p>{{ hui }}</p>
        </li>
        <li @click="stock">
          <p>
            <img :src="require('../../assets/kucun.png')" alt="" />
          </p>
          <p>{{ ku }}</p>
        </li>
        <li @click="reserve">
          <p>
            <img :src="require('../../assets/yvding.svg')" alt="" />
          </p>
          <p>{{ yu }}</p>
        </li>
      </ul>
      <ul class="firsthang">
        <li @click="baobiao">
          <p>
            <img :src="require('../../assets/baobiao.svg')" alt="" />
          </p>
          <p>{{ bao }}</p>
        </li>
        <li @click="bill">
          <p>
            <img :src="require('../../assets/zhangdan.png')" alt="" />
          </p>
          <p>{{ wai }}</p>
        </li>
         <li @click="shezhi">
          <p>
            <img :src="require('../../assets/shezhi.svg')" alt="" />
          </p>
          <p>{{ xi }}</p>
        </li>
        <li class="liSeven" @click="signout">
          <p>
            <img :src="require('../../assets/tuichu.png')" alt="" />
          </p>
          <p>退出</p>
        </li>
      </ul>
    </div>
    <Bottom/>
    <a-modal v-model="outModal" title="提示">
        <div style="display:flex;align-items:center;">
            <a-icon style="color:#faad14;font-size:32px;margin-right:10px;" type="question-circle" />
            <span>确定要退出吗？</span> 
        </div>
        <template slot="footer">
            <div class="chetaiFooter">
                <a-button @click="outModal = false">取消</a-button>
                <a-button type="primary" @click="signout">确定</a-button>
            </div>
        </template>
    </a-modal>
  </div>
</template>
<script>
import Bottom from "../bottom/bottom";
import Cookies from "js-cookie";
import { mapState , mapActions } from "vuex";
export default {
  data() {
    return {
      ying: "营业",
      hui: "会员系统",
      ku: "库存管理",
      yu: "预定",
      bao: "统计报表",
      xi: "系统设置",
      wai: "账单流水",
      outModal:false
    };
  },
  components: {
    Bottom,
  },
  created() {},
  computed: {
    ...mapState(["topTabList"]),
  },
  methods: {
    ...mapActions(['updateTabListAction','activeNameAction']),
    vuexHandle(data){
      this.activeNameAction(data.name)
      let arr = JSON.parse(JSON.stringify(this.topTabList))
      let i = arr.findIndex(item => item.name == data.name)
      if(i < 0){
        data.isDetele = true
        arr.push(data)
        this.updateTabListAction(arr)
      }
    },
    baobiao(){
      this.vuexHandle({
        title:'统计报表',
        path:'/reportForm/statistics',
        name: 'ReportForm'
      })
      this.$router.push("/reportForm/statistics");
    },
    yBtn(){
      this.vuexHandle({
        title:'营业',
        path:'/yingye',
        name: 'Yingye'
      })
      this.$router.push("/yingye");
    },
    shezhi() {
      this.vuexHandle({
        title:'系统设置',
        path:'/install/index',
        name: 'install'
      })
      this.$router.push("/install/index");
    },
    bill() {
      this.vuexHandle({
        title:'账单流水',
        path:'/bill/flow',
        name: 'bill'
      })
      this.$router.push("/bill/flow");
    },
    signout() {
      // let Ar=JSON.parse(localStorage.getItem('Ar'));
      // let username = localStorage.getItem('username')
      // let user=JSON.parse(localStorage.getItem('user'));
      // localStorage.clear()
      // localStorage.setItem('username',username)
      
      // localStorage.setItem('Ar',JSON.stringify(Ar))
      // localStorage.setItem('user',JSON.stringify(user))
      // Cookies.remove('Access-Token')

      // this.$message.success('退出成功')
      // this.updateTabListAction([{
      //   title:'主页面',
      //   path:'/about',
      //   name: 'About',
      //   isDetele:false
      // }])
      // this.activeNameAction('About')
      // this.$router.push("/");
      this.vuexHandle({
        title:'营业',
        path:'/yingye',
        name: 'Yingye'
      })
      this.$router.push({
        path:'/yingye',
        query:{
          jiaoban:'logout'
        }
      })
    },
    mis() {
      this.vuexHandle({
        title:'会员系统',
        path:'/mis/system',
        name: 'mis'
      })
      this.$router.push("/mis/system");
    },
    stock() {
      this.vuexHandle({
        title:'库存管理',
        path:'/stock/goodsList',
        name: 'stock'
      })
      this.$router.push("/stock/goodsList");
    },
    reserve() {
      this.vuexHandle({
        title:'预定',
        path:'/reserve/list',
        name: 'reserve'
      })
      this.$router.push("/reserve/list");
    },
  },
};
</script>
<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
}
.box_ul {
  width: 100%;
  height: 100%;
  position: relative;
  .menuBox{
    position: absolute;
    width: 100%;
    top: 45%;
    transform: translateY(-50%);
  }
  .firsthang {
    list-style: none;
    display: flex;
    justify-content: center;
    li {
      background: #384859;
      cursor: pointer;
      margin: 1px;
      height: 24vh;
      width: 24vh;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      p {
        color: white;
        text-align: center;
        font-size: 3vh;
        img {
          height: 8vh;
        }
      }
    }
    li:hover {
      background: #eb651a;
    }
    .liSeven {
      background-color: #ff6600;
    }

  }
}
.chetaiFooter{
    ::v-deep .ant-btn{
        width: 70px;
        &:hover,&:focus{
            border-color: #ff6700;
            color: #ff6700;
        }
    }
    ::v-deep .ant-btn-primary{
        background-color: #ff6700;
        border-color: #ff6700;
        &:hover{
            color: #ffffff;
        }
    }
}
</style>
